export default function Art() {
    const painting = {
        id: 111,
        width: 11,
        height: 14,
        name: 'Darwin',
        description: 'Dkfdfkhdkjfh kdjfhkdfhdk dfdfkhdfk dfdkfjdlkf.',
        url: '',
        frame: '11X12',
    };
    return <div>art</div>;
}
