import { createSlice } from '@reduxjs/toolkit';

const themeFromLocalStorage = localStorage.getItem('theme');

const initialState = {
    theme: themeFromLocalStorage
        ? JSON.parse(themeFromLocalStorage)
        : 'light',
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload ? 'light' : 'dark';
        },
    },
});

export const { setTheme } = themeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTheme = (state: any) => state.theme.theme;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default themeSlice.reducer;
