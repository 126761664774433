import { createClient } from '@supabase/supabase-js';

// prod
const supabaseUrl = 'https://sqgjmcwpwyysnlbdiypg.supabase.co';
const supabaseKey =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNxZ2ptY3dwd3l5c25sYmRpeXBnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQyMDA3MzUsImV4cCI6MTk5OTc3NjczNX0.MijX0kew9sPuXimWcifj1AlXwGcwjnnLDqQYkRXl67U';

// local
// const supabaseUrl = 'http://localhost:54321';
// const supabaseKey =
//     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0';

export const supabase = createClient(supabaseUrl, supabaseKey);
