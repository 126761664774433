import {
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormHelperText,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';

export default function PasswordInput(props: any) {
    const {
        name,
        label,
        value,
        error = null,
        onChange,
        variant,
    } = props;

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () =>
        setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    return (
        <FormControl
            {...(error && { error: true })}
            variant={variant || 'outlined'}>
            <InputLabel htmlFor="outlined-adornment-password">
                {label || 'Password'}
            </InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? (
                                <VisibilityOff />
                            ) : (
                                <Visibility />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
                label={label || 'Password'}
                name={name}
                value={value}
                onChange={onChange}
            />
            <FormHelperText id="component-error-text">
                {error}
            </FormHelperText>
        </FormControl>
    );
}
