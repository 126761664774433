import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox as MuiCheckbox } from '@mui/material';

export default function Checkbox(props: any) {
    const { name, label, value, onChange } = props;

    const convertToDefEventPara = (name: string, value: boolean) => ({
        target: {
            name,
            value,
        },
    });

    return (
        <FormControl>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        name={name}
                        color="primary"
                        checked={value}
                        onChange={(e) =>
                            onChange(
                                convertToDefEventPara(
                                    name,
                                    e.target.checked,
                                ),
                            )
                        }
                    />
                }
                label={label}
            />
        </FormControl>
    );
}
