import Input from './Input';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Checkbox from './Checkbox';
import MuiButton from './Button';
import PasswordInput from './PasswordInput';

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    MuiButton,
    PasswordInput,
};

export default Controls;
