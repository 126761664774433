import {
    Action,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { Constants } from '../../constants';
// import { fetchCount } from './counterAPI';
import type { RootState } from '../../store/store';
import { supabase } from '../../client';

const initialState: {
    value: number;
    status: string;
    error: {
        name: string;
        message: string;
    };
    username: string;
    unavailableUsernames: any[];
    paintings: any[];
    userSignin: {
        userInfo: any;
    };
    cart: {
        cartItems: any;
        shippingAddress: any;
        paymentMethod: string;
    };
} = {
    value: 0,
    status: 'idle',
    error: { name: '', message: '' },
    username: '',
    unavailableUsernames: [],
    paintings: [],
    userSignin: {
        userInfo: localStorage.getItem('userInfo')
            ? // @ts-ignore
              JSON.parse(localStorage.getItem('userInfo'))
            : null,
    },
    cart: {
        cartItems: localStorage.getItem('cartItems')
            ? // @ts-ignore
              JSON.parse(localStorage.getItem('cartItems'))
            : [],
        shippingAddress: localStorage.getItem('shippingAddress')
            ? // @ts-ignore
              JSON.parse(localStorage.getItem('shippingAddress'))
            : {},
        paymentMethod: 'PayPal',
    },
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
    'auth/fetchCount',
    async (amount) => {
        // const response = await fetchCount(amount);
        // // The value we return becomes the `fulfilled` action payload
        // return response.data;
        return 1;
    },
);

// export const getProfileAsync: any = createAsyncThunk(
//     'auth/fetchProfile',
//     async (id) => {
//         const response = await axios.get(baseUrl + 'artist/' + id);
//         // The value we return becomes the `fulfilled` action payload
//         return response.data;
//     },
// );

export const checkUsernameAvailabilityAsync: any = createAsyncThunk(
    'auth/fetchUsername',
    async (username) => {
        const { data, error } = await supabase
            .from('profiles')
            .select()
            .eq('username', username);
        console.log(error, data);
        // The value we return becomes the `fulfilled` action payload
        if (error) {
            throw error;
        }
        return data;
    },
);

export const createUserAsync: any = createAsyncThunk(
    'auth/createUser',
    async (
        input: {
            email: string;
            password: string;
            options?: { data: any; emailRedirectTo: string };
        },
        { getState },
    ) => {
        const state = getState() as RootState;
        console.log(
            'checking getState username:',
            state.auth.username,
        );
        input.options = input.options ?? {
            data: {
                username: state.auth.username
                    ? state.auth.username
                    : generateUsername(input.email),
            },
            emailRedirectTo: 'https://artazium.com/thankyou',
        };
        const { data, error } = await supabase.auth.signUp(input);
        console.log('input:', input);
        console.log(error, data);

        if (error) {
            throw error;
        }
        return data;
    },
);

// export const loginUserAsync: any = createAsyncThunk(
//     'auth/createUser',
//     async (data) => {
//         const response = await axios.post(baseUrl + 'signin/', data);
//         // The value we return becomes the `fulfilled` action payload
//         return response.data;
//     },
// );

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        resetStatus: (state) => {
            console.log('resetting status');

            state.status = 'idle';
        },
        setUser: (state, action) => {
            console.log('username state:', action);

            state.username = action.payload;
        },

        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // .addCase(getProfileAsync.pending, (state) => {
            //     state.status = 'loading';
            // })
            .addCase(
                checkUsernameAvailabilityAsync.pending,
                (state) => {
                    state.status = 'loading_username_check';
                },
            )
            .addCase(
                checkUsernameAvailabilityAsync.fulfilled,
                (
                    state,
                    action: { payload: any[]; meta: { arg: string } },
                ) => {
                    console.log('action:', action);
                    if (action.payload.length) {
                        state.unavailableUsernames.push(
                            action.payload[0],
                        );
                    } else {
                        state.username = action.meta.arg;
                    }
                    state.status = 'idle';
                },
            )

            .addCase(
                checkUsernameAvailabilityAsync.rejected,
                (state) => {
                    state.status = 'error';
                },
            )
            // .addCase(getProfileAsync.fulfilled, (state, action) => {
            //     state.status = 'idle';
            //     state.paintings = action.payload;
            // })
            .addCase(createUserAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createUserAsync.fulfilled, (state, action) => {
                state.status = 'success';
                state.userSignin.userInfo = action.payload;
                console.log(
                    action.payload.user.user_metadata.username,
                );
                state.username =
                    action.payload.user.user_metadata.username;
            })
            .addCase(createUserAsync.rejected, (state, action) => {
                state.status = 'error';
                // state.userSignin.userInfo = action.payload;
                console.log('error action:', action);
                state.error = action.error;
            });
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.auth.value;
export const selectPaintings = (state: RootState) =>
    state.auth.paintings;
export const selectAuthState = (state: RootState) => state.auth;
export const selectUserName = (state: RootState) =>
    state.auth.username;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: any) => (dispatch: any, getState: any) => {
//         const currentValue = selectCount(getState());
//         if (currentValue % 2 === 1) {
//             dispatch(incrementByAmount(amount));
//         }
//     };
export const { setUser, resetStatus } = authSlice.actions;

export default authSlice.reducer;

// CREATE OR REPLACE FUNCTION public.trigger_new_profile()
//  RETURNS trigger
//  LANGUAGE plpgsql
//  SECURITY DEFINER
// AS $function$begin
//     insert into public.profiles(user_id, username)
//     VALUES(new.id, new.raw_user_meta_data->>username);
// 		RETURN new;
// END;$function$

const generateUsername = (email: string) => {
    const map: { [key: string]: string } = {
        a: '0',
        b: '1',
        c: '2',
        d: '3',
        e: '4',
        f: '5',
        g: '6',
        h: '7',
        i: '8',
        j: '9',
        k: '10',
        l: '11',
        m: '12',
        n: '13',
        o: '14',
        p: '15',
        q: '16',
        r: '17',
        s: '18',
        t: '19',
        u: '20',
        v: '21',
        w: '22',
        x: '23',
        y: '24',
        z: '25',
        A: '26',
        B: '27',
        C: '28',
        D: '29',
        E: '30',
        F: '31',
        G: '32',
        H: '33',
        I: '34',
        J: '35',
        K: '36',
        L: '37',
        M: '38',
        N: '39',
        O: '40',
        P: '41',
        Q: '42',
        R: '43',
        S: '44',
        T: '45',
        U: '46',
        V: '47',
        W: '48',
        X: '49',
        Y: '50',
        Z: '51',
    };

    let username = '';
    const emailParts = email.split('@');

    for (const char of emailParts[1]) {
        if (char === '.') {
            continue;
        }
        if (char in map) {
            username += map[char];
            continue;
        }
        username += username;
    }

    return emailParts[0] + '-' + username;
};
