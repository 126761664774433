import { Grid, Paper } from '@mui/material';
import './temp-landing.scss';

export default function ThankYouPage() {
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className="temp-landing-page"
            sx={{
                height: '100vh',
            }}>
            <Paper
                sx={{
                    p: 4,
                    m: 3,
                    height: 'fit-content',
                    background:
                        'radial-gradient(farthest-corner at 0% 0%, rgb(238 210 174 / 73%) 20%, rgba(148,187,233,1) 100%);',
                    backdropFilter: 'blur(10px)',
                }}
                className="success text-center  m-auto">
                Your username is reserved!
                <br /> <br />
                We will send you an invitation to your page when the
                website is released. <br /> <br /> Thank you!
            </Paper>
        </Grid>
    );
}
