import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const MuiCustomButton = styled(Button)(({ theme }) => ({
    // fontFamily: "inherit",
    textTransform: 'none',
}));

export default function MuiButton(props: any) {
    // const useStyles = makeStyles((theme) => ({
    //     root: {
    //         margin: theme.spacing(0.5),
    //     },
    //     label: {
    //         textTransform: "none",
    //     },
    // }));

    const { text, size, color, variant, onClick, ...other } = props;
    // const classes = useStyles();

    return (
        <MuiCustomButton
            variant={variant || 'contained'}
            size={size || 'large'}
            color={color || 'primary'}
            onClick={onClick}
            {...other}
            // classes={{ root: classes.root, label: classes.label }}
        >
            {text}
        </MuiCustomButton>
    );
}
