import Grid from '@mui/material/Grid';
import { Box, Divider, Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import MuiButton from '../../controls/Button';
import { createUserAsync } from './authSlice';
import { useForm } from '../../controls/useForm';
import Controls from '../../controls/Controls';
import React from 'react';

const SignUp: React.FC = () => {
    const dispatch = useDispatch();

    const initialFValues = {
        email: '',
        password: '',
        // confirmPassword: '',
    };

    const validate = (fieldValues = values) => {
        let temp: any = { ...errors };
        if ('email' in fieldValues) {
            temp.email = fieldValues.email
                ? ''
                : 'Email is required.';
            if (!/$^|.+@.+..+/.test(fieldValues.email)) {
                temp.email = 'Email is not valid.';
            }
        }

        if ('password' in fieldValues)
            temp.password = fieldValues.password
                ? ''
                : 'Password is required.';

        // if ('confirmPassword' in fieldValues) {
        //     temp.confirmPassword = fieldValues.confirmPassword
        //         ? ''
        //         : 'Please confirm your password.';
        // }

        // if (
        //     'password' in fieldValues
        //     &&
        //     'confirmPassword' in fieldValues
        // ) {
        // if (
        //     fieldValues.confirmPassword !== fieldValues.password
        // ) {
        //     temp.confirmPassword = 'Passwords must match.';
        // }
        // }
        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            console.log(fieldValues, 'equal to', values);
        return Object.values(temp).every((x) => x === '');
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
    }: any = useForm(initialFValues, true, validate);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (validate()) {
            dispatch(
                createUserAsync({
                    email: values.email,
                    password: values.password,
                }),
            );
            resetForm();
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" gap={4}>
                <Controls.Input
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                />

                <Controls.PasswordInput
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleInputChange}
                    error={errors.password}
                />
                {/* <Controls.PasswordInput
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    onChange={handleInputChange}
                    error={errors.confirmPassword}
                /> */}

                <MuiButton type="submit" text="Create an account" />
            </Grid>
        </form>
    );
};

export default SignUp;
