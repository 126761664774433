import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../components/auth/authSlice';
import themeSlice from '../components/themeSlice';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        theme: themeSlice,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
